<template>
  <div class="app-container">
    <div class="head-container">
      <!-- <quick-select v-model="query.distributorId" filterable clearable url="api/distributor" placeholder="选择经销商" value-field="id" @change="toQuery" class="filter-item" style="width: 180px;" /> -->
      <quick-select class="filter-item" clearable v-model="query.distributorId" filterable value-field="id" url="api/distributor" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }" placeholder="请选择经销商" @change="toQuery" style="width: 230px;">
        <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
      </quick-select>
      <quick-select class="filter-item" clearable v-model="query.subjectId" :filterable="true" value-field="id" url="api/subject" placeholder="请选择活动" style="width: 230px;" @change="toQuery" />
      <el-select v-model="query.status" placeholder="支付状态" style="width:150px" class="filter-item" clearable @change="toQuery">
        <el-option v-for="item in status" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="支付日期" end-placeholder="支付日期" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 240px;" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button type="primary" class="filter-item" size="mini" icon="el-icon-plus" @click="add">报名</el-button>
      <el-button class="filter-item" type="info" size="mini" icon="el-icon-download" :loading="downloading" @click="toDownload">导出</el-button>

      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">导入</el-button>
      </el-upload>

      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;" border>
      <el-table-column prop="formCode" label="单号" width="120" />
      <el-table-column prop="distributorErpCode" label="经销商编码" width="110" />
      <el-table-column prop="distributorName" label="经销商" width="140" show-overflow-tooltip/>
      <el-table-column prop="subjectName" label="活动名称" min-width="120" show-overflow-tooltip/>
      <el-table-column prop="payMoney" label="支付金额" align="right" width="100" :formatter="$price" />
      <el-table-column prop="rebackMoney" label="返还金额" align="right" width="100" :formatter="$price" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="100" />
         
      <el-table-column label="支付时间" width="120" :formatter="r => {return r.payTime?new Date(r.payTime).format('yyyy/MM/dd hh:mm'):''}" />  
      <el-table-column label="状态" width="110" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>     
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <template>
            <el-button size="mini" type="text" @click="detail(scope.row)" v-if="scope.row.status=='unpay'">编辑</el-button>
            <el-button size="mini" type="text" @click="view(scope.row)" v-else>查看</el-button>
            <el-button size="mini" type="text" @click="rebackMoney(scope.row)" v-if="scope.row.status=='paySuccess'">返还</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本保证金单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini" v-if="scope.row.status=='unpay'">删除</el-button>
            </el-popover>
          </template>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" :is-add="isAdd" />
    <r-form ref="rform" />
    <importor ref="importor" />
  </div>
</template>

<script>
import { del } from "@/api/deposit";
import initData from "@/mixins/initData";
import eForm from "./form";
import rForm from "./reback";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import { getToken } from "@/utils/auth";
import Importor from "./import";
import config from "@/config";

export default {
  components: { eForm, rForm, Importor },
  mixins: [initData],
  data() {
    return {
      downloading: false,
      uploading: false,
      uploadUrl: config.hosts.request + "api/deposit/uploadExcel",
      uploadHeaders: {},

      status: {
        unpay: {
          type: "warning",
          label: "待支付",
          id: 0,
        },
        pay: {
          type: "primary",
          label: "支付中",
          id: 1,
        },
        paySuccess: {
          type: "success",
          label: "支付成功",
          id: 2,
        },
        payFailed: {
          type: "error",
          label: "支付失败",
          id: 3,
        },
        reback: {
          type: "info",
          label: "已退还",
          id: 4,
        },
        storIn: {
          type: "info",
          label: "已转入余额",
          id: 5,
        },
      },
      query: {
        distributorId: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/deposit/ent";
      let query = JSON.parse(JSON.stringify(this.query));
      query.sort = "createAt,desc";
      this.params = query;
      if (query.dateRange && query.dateRange.length === 2) {
          query.payTimeMin = query.dateRange[0];
          query.payTimeMax = query.dateRange[1];
      }
      delete query.dateRange;
      return true;
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    detail(row) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.restForm(JSON.parse(JSON.stringify(row)));
    },
    rebackMoney(row) {
      const _this = this.$refs.rform;
      _this.restForm(row);
      // this.$refs.rform.resetForm(row);
    },
    view(row) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.restForm(JSON.parse(JSON.stringify(row)));
      _this.disabled = true;
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除保证金成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloading = true;
      download("api/deposit/download", this.params)
        .then((result) => {
          downloadFile(result, "保证金", "xlsx");
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
        })
        .finally(() => {
          this.downloading = false;
        });
    },

    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(response) {
      this.uploading = false;
      // 导入失败返回导入的错误信息
      if (response.success == false) {
        this.$refs.importor.dialogVisible = true;
        this.$refs.importor && this.$refs.importor.resetForm(response.errList);
      } else {
        // 导入成功 提示
        this.$notify({
          title: "导入成功",
          type: "success",
          duration: 2500,
        });
        this.init();
      }
    },
    handleUploadError(err) {
      if (err.status != 200) {
        this.$notify({
          title: "导入失败",
          type: "error",
          duration: 2500,
        });
      }
      this.uploading = false;
    },
  },
  mounted() {
    this.uploadHeaders.Authorization = "Bearer " + getToken();
    this.init();
  },
};
</script>